<template>
  <div class="privacy-policy">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item>隐私策略</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>隐私策略</h3>
      <p>privacyPolicy</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'privacyPolicy'
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
.privacy-policy {
  @include body;
}
</style>
